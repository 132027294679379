/*********************
 *
 * JAVASCRIPT
 *
 *********************/

/*********************
 * IMPORT STYLES
 *********************/

import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

/*********************
 * IMPORT LIBRARIES (REMOVE IF UNUSED)
 *********************/

/*********************
 * GLOBAL VARIABLES
 *********************/

let clampFontSize;
const bodyElement = document.querySelector('body');

/*********************
 * CLAMP FONT SIZE VARIABLE
 *********************/

function updateClampFontSize() {
  clampFontSize = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')) / 16;
}

updateClampFontSize();

/*********************
 * GET VIEWPORT DIMENSIONS
 *********************/

function updateViewportDimensions() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return { width: x, height: y };
}

// setting the viewport width
var viewport = updateViewportDimensions();
var vpWidth = viewport.width;

/*
 * Throttle Resize-triggered Events
 */
var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout(timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 75;

/*********************
 * FIX BODY
 *********************/

function fixBody(bodyClass) {
  let scrollPosition = window.pageYOffset;
  bodyElement.classList.add(bodyClass);
  bodyElement.style.top = '-' + scrollPosition + 'px';
  bodyElement.setAttribute('data-scroll', String(scrollPosition));
}

/*********************
 * UNFIX BODY
 *********************/

function unfixBody(bodyClass) {
  let scrollPosition = bodyElement.getAttribute('data-scroll');
  bodyElement.classList.remove(bodyClass);
  bodyElement.style.removeProperty('top');
  window.scrollTo(0, parseInt(scrollPosition));
}

/*********************
 * ADJUST MENU
 *********************/
var adjustMenu = function (vpWidth) {
  var topNav = jQuery('.main-menu');

  if (vpWidth < 768) {
    jQuery('.nav li').unbind('mouseenter mouseleave');
    jQuery('.nav li a.parent')
      .unbind('click')
      .bind('click', function (e) {
        // must be attached to anchor element to prevent bubbling
        e.preventDefault();
        jQuery(this).parent('li').toggleClass('hover');
      });
  } else if (vpWidth >= 768) {
    topNav.find('li').removeClass('hover');
    topNav.find('li a').unbind('click');
    topNav
      .find('li')
      .unbind('mouseenter mouseleave')
      .bind('mouseenter mouseleave', function () {
        // must be attached to li so that mouseleave is not triggered when hover over submenu
        jQuery(this).toggleClass('hover');
      });
  }
};

/*********************
 * BUILDING VIEWER
 *********************/
jQuery('.building-view polygon').on('click', function (e) {
  e.preventDefault();
  if (vpWidth >= 768) {
    var floorNumber = jQuery(this).data('floor');
    window.location = floorUrls[floorNumber];
  }
});

const floorSelect = document.querySelector('.viewer-select select');
if (floorSelect) {
  floorSelect.addEventListener('change', () => {
    window.location = floorSelect.value;
  });
}

jQuery(window).resize(function () {
  viewport = updateViewportDimensions();
  vpWidth = viewport.width;

  waitForFinalEvent(function () {
    adjustMenu(vpWidth);
  }, timeToWaitForLast);
});
/* end resize functions */

jQuery('.dwelling-slider').on(
  'init reInit afterChange',
  function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    jQuery('.paging-info').text(i + '/' + slick.slideCount);
  }
);

/* Page load scripts */
jQuery(document).ready(function ($) {
  jQuery('.toggleMenu').click(function (e) {
    e.preventDefault();
    jQuery(this).toggleClass('active');
    jQuery('.main-menu').toggleClass('active');
  });

  //building viewer
  var currentView = 1;
  var viewCount = jQuery('.building-view').length;

  jQuery('.building-next').click(function () {
    jQuery('.building-view').removeClass('active');
    currentView++;
    if (currentView === viewCount + 1) {
      currentView = 1;
    }
    jQuery('#view' + currentView).addClass('active');
  });

  jQuery('.building-prev').click(function () {
    jQuery('.building-view').removeClass('active');
    currentView--;
    if (currentView === 0) {
      currentView = viewCount;
    }
    jQuery('#view' + currentView).addClass('active');
  });


  jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
    // console.log('x');

    if (formId == 2) {
      jQuery('.download-section').show();
      // console.log('2');
    }
  });

  // SLIDERS

  $('.inner-image-slider').slick({
    infinite: true,
    speed: 850,
    slidesToShow: 1,
    arrows: true,
    //variableWidth: true
  });

  $('.fw-slider').slick({
    dots: false,
    infinite: true,
    speed: 850,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    zIndex: 50,
    arrows: false,
  });

  $('.usp-slider').slick({
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
  });

  jQuery('.popup-btn').click(function (e) {
    e.preventDefault();
    var slideId = $(this).attr('data-id') - 1;
    var popupContainer = jQuery('#popup-ctn');
    popupContainer.toggleClass('active');
    fixBody('popup-active');
    jQuery('html, body').animate(
      {
        scrollTop: popupContainer.offset().top,
      },
      1000,
      function () {}
    );

    $('.usp-slider').slick('slickGoTo', slideId, true);

    //reset on click out of container
    popupContainer
      .click(function () {
        if (popupContainer.hasClass('active')) {
          popupContainer.removeClass('active');
          unfixBody('popup-active');
        }
      })
      .children()
      .click(function (e) {
        e.stopPropagation();
      });
  });

  jQuery('.p-features .dw-slide-btn').click(function (e) {
    e.preventDefault();

    $('.dwelling-slider').slick({
      initialSlide: 12,
    });
  });

  jQuery('.dw-slide-btn').click(function (e) {
    e.preventDefault();
    var popupContainer = jQuery('#slideshow-ctn');
    popupContainer.toggleClass('active');

    $('.dwelling-slider').slick({
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
    });

    //reset on click out of container
    popupContainer
      .click(function () {
        if (popupContainer.hasClass('active')) {
          popupContainer.removeClass('active');
        }
      })
      .children()
      .click(function (e) {
        e.stopPropagation();
      });
  });

  jQuery('.plan-slide-btn').click(function (e) {
    e.preventDefault();
    var slideId = $(this).attr('data-id') - 1;
    var popupContainer = jQuery('#slideshow-ctn2');
    popupContainer.toggleClass('active');

    $('.plan-slider').slick({
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
    });

    $('.plan-slider').slick('slickGoTo', slideId, true);

    /*        jQuery('html, body').animate({
                    scrollTop: popupContainer.offset().top - 120
                }, 1000, function () {
                });*/

    //reset on click out of container
    popupContainer
      .click(function () {
        if (popupContainer.hasClass('active')) {
          popupContainer.removeClass('active');
        }
      })
      .children()
      .click(function (e) {
        e.stopPropagation();
      });
  });

  jQuery('.faq-head').click(function () {
    var item = jQuery(this).parents('.faq-item');
    item.toggleClass('active');
    item.siblings().removeClass('active');
  });

  jQuery('.close-popup').click(function () {
    jQuery('#popup-ctn').removeClass('active');
    unfixBody('popup-active');
  });

  jQuery('.close-slideshow').click(function () {
    jQuery('#slideshow-ctn, #slideshow-ctn2').removeClass('active');
  });

  /* Object-fit fallback */
  if (!Modernizr.objectfit) {
    $('.img-container').each(function () {
      var $container = $(this),
        imgUrl = $container.find('img').prop('src');
      if (imgUrl) {
        $container
          .css('backgroundImage', 'url(' + imgUrl + ')')
          .addClass('compat-object-fit');
      }
    });
  }
});
/* end of as page load scripts */

document.querySelector('.toggleMenu').addEventListener('click', (e) => {
  if (document.querySelector('.header').classList.contains('transparent')) {
    document.querySelector('.header.transparent').classList.toggle('white-bg');
  }
});

/**************
 * Dwellshape hover tooltips
 *************/
function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function createTooltipItem(label, parent) {
  let li = document.createElement('li');
  let title = document.createElement('span');
  title.innerText = label;
  let value = document.createElement('span');

  li.append(title, value);
  parent.appendChild(li);

  return value;
}

const dwellShapes = document.querySelectorAll(
  '.dwell-shape:not(.hidden) > rect, .dwell-shape:not(.hidden) > polygon, .dwell-shape:not(.hidden) > path'
);
if (dwellShapes.length > 0) {
  // add click to dwell shape
  dwellShapes.forEach((el) => {
    el.addEventListener('click', () => {
      window.location.href = '/woningen/' + el.dataset.dwell;
    });
  });

  // add tooltips
  const tooltip = document.createElement('div');
  tooltip.classList.add('tooltip');

  const tooltipTitle = document.createElement('h4');
  tooltip.appendChild(tooltipTitle);

  let tooltipList = document.createElement('ul');
  tooltip.appendChild(tooltipList);

  // change these strings to change tooltip labels
  let tooltipPrice = createTooltipItem('Prijs', tooltipList);
  let tooltipArea = createTooltipItem('Oppervlakte', tooltipList);
  let tooltipBedrooms = createTooltipItem('Kamers', tooltipList);
  // let tooltipStatus = createTooltipItem('Status', tooltipList);

  tooltip.appendChild(tooltipList);

  dwellShapes.forEach((el) => {
    el.addEventListener('mouseenter', () => {
      tooltipTitle.textContent = el.dataset.name;
      tooltipArea.innerHTML = el.dataset.surfaceArea;
      tooltipBedrooms.textContent = Number(el.dataset.bedrooms) + 1;
      tooltipPrice.textContent = Number(el.dataset.price)
        ? `€ ${formatNumber(el.dataset.price)}`
        : el.dataset.price;
      // tooltipStatus.textContent = el.dataset.status;

      document.querySelector('.floor-container').appendChild(tooltip);
    });

    el.addEventListener('mouseleave', () => {
      tooltip.remove();
    });
  });

  const onMouseMove = (e) => {
    // subtract offset to prevent tooltip shrinking on edge of screen
    tooltip.style.left = `${e.clientX - 200}px`;
    tooltip.style.top = e.clientY + 'px';
  };
  document.addEventListener('mousemove', onMouseMove);
}

/**************
 * Dwelling filters
 *************/
let dwellingItems = document.querySelectorAll(
  '.dwelling-grid--filter .dg-item'
);

let minPrice = Infinity;
let maxPrice = 0;
let sliderValues = [0, 0];
let checkboxSelectors = '*';

// setup price slider
// if slider
var priceSlider = document.getElementById('slider');

if (dwellingItems) {
  // get max and min prices
  dwellingItems.forEach((el) => {
    const price = Number(el.dataset.price);

    if (price > maxPrice) {
      maxPrice = price;
    }

    if (price < minPrice) {
      minPrice = price;
    }
  });

  if (minPrice == maxPrice) {
    document.querySelector('.filters__dwelling-price').remove();

    // set filter values
    sliderValues[0] = 0;
    sliderValues[1] = Infinity;
  } else {
    // display min and max values
    if (maxPrice > 10) {
      document.querySelector(
        '.filters__dwelling-price__minmax .min span'
      ).innerText = formatNumber(minPrice);
      document.querySelector(
        '.filters__dwelling-price__minmax .max span'
      ).innerText = formatNumber(maxPrice);
    }

    if (priceSlider) {
      noUiSlider.create(priceSlider, {
        start: [minPrice, maxPrice],
        connect: true,
        range: {
          min: minPrice,
          max: maxPrice,
        },
      });
      // set start values
      sliderValues = priceSlider.noUiSlider.get();

      priceSlider.noUiSlider.on('end', () => {
        // filter based on values
        sliderValues = priceSlider.noUiSlider.get();

        // recalculate filter
        $container.isotope();
      });
    }
  }
}

// EXTRA LAYOUT MODE NONE

Isotope.Item.prototype._create = function() {
  // assign id, used for original-order sorting
  this.id = this.layout.itemGUID++;
  // transition objects
  this._transn = {
    ingProperties: {},
    clean: {},
    onEnd: {}
  };
  this.sortData = {};
};

Isotope.Item.prototype.layoutPosition = function() {
  this.emitEvent( 'layout', [ this ] );
};

Isotope.prototype.arrange = function( opts ) {
  // set any options pass
  this.option( opts );
  this._getIsInstant();
  // just filter
  this.filteredItems = this._filter( this.items );
  // flag for initalized
  this._isLayoutInited = true;
};

// layout mode that does not position items
Isotope.LayoutMode.create('none');

// END EXTRA LAYOUT MODE NONE


var $container = $('.dwelling-grid--filter').imagesLoaded(function () {
  // init isotope after all images have loaded

  $container.isotope({
    itemSelector: '.dg-item',
    layoutMode: 'none',
/*    fitRows: {
      columnWidth: 300,
      columnHeight: 500,
      gutter: 20,
    },
*/
    filter: filterFunc,
    transitionDuration: 0,
  });
  checkboxChanges();

  function filterFunc() {
    let $this;
    $this = $(this);

    if (
      Number(this.dataset.price) >= Number(sliderValues[0]) &&
      Number(this.dataset.price) <= Number(sliderValues[1]) &&
      $this.is(checkboxSelectors)
    ) {
      return true;
    }

    return false;
  }

  function checkboxChanges(){
    var $checkboxes = $('.filters .checkbox');
    let inclusives = [];
    let properties = [];

    $checkboxes.each(function (i, elem) {
      if (elem.checked) {
        if (elem.value == '.s-available' || elem.value == '.s-dakterras') {
          properties.push(elem.value);
        } else {
          inclusives.push(elem.value);
        }
      }
    });

    // add extra properties to inclusive values
    inclusives = inclusives.map((el) => {
      return el + properties.join('');
    });

    // if only properties are selected filter based on properties only
    if (inclusives.length < 1) {
      inclusives = [properties.join('')];
    }

    // combine inclusive filters
    checkboxSelectors = inclusives[0] != '' ? inclusives.join(', ') : '*';

    // recalculate filters
    $container.isotope();
  }

  // combine checkbox filters
  var $checkboxes = $('.filters .checkbox');
  $checkboxes.change(function () {
    checkboxChanges();
  });
});

/**************
 * GA4 EVENTS
 *************/

jQuery('.tel-link').click(function (e) {
  //console.log('tel');
  gtag('event', 'click_phone');
});

jQuery('.email-link, .email-btn').click(function (e) {
  //console.log('email');
  gtag('event', 'click_email');
});

jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
  if (formId == 1) {
    //console.log('submitted');
    gtag('event', 'form_submitted');
  }
});

/* fitVids */
(function ($) {
  'use strict';

  $.fn.fitVids = function (options) {
    var settings = {
      customSelector: null,
    };

    if (!document.getElementById('fit-vids-style')) {
      var div = document.createElement('div'),
        ref =
          document.getElementsByTagName('base')[0] ||
          document.getElementsByTagName('script')[0],
        cssStyles =
          '&shy;<style>.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}</style>';

      div.className = 'fit-vids-style';
      div.id = 'fit-vids-style';
      div.style.display = 'none';
      div.innerHTML = cssStyles;

      ref.parentNode.insertBefore(div, ref);
    }

    if (options) {
      $.extend(settings, options);
    }

    return this.each(function () {
      var selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='youtube.com']",
        "iframe[src*='youtube-nocookie.com']",
        "iframe[src*='kickstarter.com'][src*='video.html']",
        'object',
        'embed',
      ];

      if (settings.customSelector) {
        selectors.push(settings.customSelector);
      }

      var $allVideos = $(this).find(selectors.join(','));
      $allVideos = $allVideos.not('object object'); // SwfObj conflict patch

      $allVideos.each(function () {
        var $this = $(this);
        if (
          (this.tagName.toLowerCase() === 'embed' &&
            $this.parent('object').length) ||
          $this.parent('.fluid-width-video-wrapper').length
        ) {
          return;
        }
        var height =
            this.tagName.toLowerCase() === 'object' ||
            ($this.attr('height') && !isNaN(parseInt($this.attr('height'), 10)))
              ? parseInt($this.attr('height'), 10)
              : $this.height(),
          width = !isNaN(parseInt($this.attr('width'), 10))
            ? parseInt($this.attr('width'), 10)
            : $this.width(),
          aspectRatio = height / width;
        if (!$this.attr('id')) {
          var videoID = 'fitvid' + Math.floor(Math.random() * 999999);
          $this.attr('id', videoID);
        }
        $this
          .wrap('<div class="fluid-width-video-wrapper"></div>')
          .parent('.fluid-width-video-wrapper')
          .css('padding-top', aspectRatio * 100 + '%');
        $this.removeAttr('height').removeAttr('width');
      });
    });
  };
  // Works with either jQuery or Zepto
})(window.jQuery || window.Zepto);
/* end fitVids */
